import classNames from 'classnames'
import React from 'react'
import type { Component } from 'constants/types'
import styles from './Embed.module.scss'

export type Props = {
  children: React.ReactNode
  rounded?: boolean
} & Component

export default function Embed({ children, className, rounded, ...props }: Props) {
  return (
    <div
      {...props}
      className={classNames('Embed', styles.this, rounded && styles['this---rounded'], className)}>
      {children}
    </div>
  )
}
