import classNames from 'classnames'
import React from 'react'
import Embed from 'components/Embed'
import VideoHead from 'components/VideoHead'
import type { Component } from 'constants/types'
import styles from './Video.module.scss'

function getYouTubeVideoId(url: string) {
  const fragments = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)

  return undefined !== fragments[2] ? fragments[2].split(/[^\w-]/i)[0] : fragments[0]
}

function getVimeoVideoId(url: string) {
  const fragments = url.match(/https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/)

  return undefined !== fragments[2] ? fragments[2] : fragments[0]
}

type Props = {
  autoplay?: boolean
  src?: string
  url?: string
} & Component

export default function Video({ autoplay, className, src: propsSrc, url, ...props }: Props) {
  let src = propsSrc

  if (!propsSrc) {
    if (url?.includes('youtu')) {
      src = `https://www.youtube.com/embed/${getYouTubeVideoId(url)}?autoplay=${
        autoplay ? 1 : 0
      }&modestbranding=1&rel=0`
    } else if (url?.includes('vimeo')) {
      src = `https://player.vimeo.com/video/${getVimeoVideoId(url)}?autoplay=${
        autoplay ? 1 : 0
      }&byline=0&color=1867d1&fun=0&portrait=0&title=0`
    }
  }

  if (!src) return null

  return (
    <Embed {...props} className={classNames('Video', styles.this, className)}>
      <VideoHead />
      <iframe
        src={src}
        allow="autoplay; fullscreen"
        allowFullScreen
        title="Video"
        className={styles.video}
      />
    </Embed>
  )
}
