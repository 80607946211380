import classNames from 'classnames'
import React from 'react'
import Text, { type TextProps } from 'components/Text'
import { useGlobalContext } from 'contexts/GlobalContext'

type Props = TextProps

export default function AuthedOnly({ className, ...props }: Props) {
  const globalContext = useGlobalContext()

  if (!globalContext.isAuthed) return null

  return <Text {...props} className={classNames('AuthedOnly', className)} />
}
