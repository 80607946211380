import { MDXProvider } from '@mdx-js/react'
import classNames from 'classnames'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import AuthedOnly from 'components/AuthedOnly'
import Copy from 'components/Copy'
import Link from 'components/Link'
import Text from 'components/Text'
import Video from 'components/Video'
import type { Component } from 'constants/types'

type Props = {
  children: string
  components?: {}
  //parser: 'mdx' | 'remark'
} & Component

export default function Markdown({
  children,
  className,
  components = {},
}: //parser,
Props) {
  const providerComponents = {
    //a: ({ href, ...props }) => <Link {...props} to={href} />,
    // eslint-disable-next-line react/no-unstable-nested-components
    a: ({ href, ...props }) => (
      <Link {...props} to={href.replace('mailto:', '').replace('tel:', '')} />
    ),
    AuthedOnly,
    Text,
    Video,
    ...components,
  }

  return (
    <Copy className={classNames('Markdown', className)}>
      <MDXProvider components={providerComponents}>
        <MDXRenderer>{children}</MDXRenderer>
      </MDXProvider>
    </Copy>
  )
}
