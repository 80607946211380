import React from 'react'
import Helmet from 'react-helmet'

type Props = {
  platforms?: string[]
}

export default function VideoHead({ platforms = ['vimeo'] }: Props) {
  let urls: string[] = []

  if (platforms?.includes('vimeo')) {
    urls = [...urls, 'https://f.vimeocdn.com', 'https://i.vimeocdn.com', 'https://player.vimeo.com']
  }

  if (platforms?.includes('youtube')) {
    urls = [
      ...urls,
      'https://www.youtube.com',
      'https://i.ytimg.com',
      'https://i9.ytimg.com',
      'https://s.ytimg.com',
    ]
  }

  return (
    <Helmet>
      {urls.map((item) => (
        <link rel="preconnect" href={item} crossOrigin key={item} />
      ))}
    </Helmet>
  )
}
